import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Alert, Form, Label, Input, FormGroup, Card, CardBody, dataLayer } from "reactstrap";


import FeatherIcon from 'feather-icons-react';
import axios from "axios";

//Import Components
import SectionTitle from "../../components/Shared/SectionTitle";
import PrivacyPolicy from "../../assets/docs/polityka_prywatnosci.pdf"

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name : "",
            email: "",
            message: "",
            privacy: false,
            successMsg : false,
            error: null
        }

        this.handleSubmit = this.handleFormSubmit.bind(this);
        this.sendMail.bind(this);
        this.callNumber.bind(this);
    }

    handleCheckboxChange = e => {
        this.setState({privacy: !this.state.privacy})
    }

    handleFormSubmit = e => {
        e.preventDefault();
        axios({
          method: 'post',
          url: `https://pozycjonowanie.upmore.pl/kontakt/index.php`,
          headers: { 'content-type': 'application/json' },
          data: this.state
        })
          .then(result => {
            this.setState({
                successMsg : result.data.sent,
                error : result.data.message,
            });
          })
          .catch(errorExt => this.setState({ error: errorExt.message }));
      };

    sendMail(){
        window.location.href="mailto:hello@upmore";
    }

    callNumber(){
        window.location.href="tel:+48537690900";
    }
    
    render() {
        return (
            <React.Fragment>
            <Container>
                {/* section title */}
                <SectionTitle title="Masz pytania? Napisz do nas!"  />

                <Row className="align-items-center justify-content-center">
                    <Col lg="6"  xs={{order:1}} className="mt-4 pt-2">
                        <Card className="rounded shadow border-0" data-aos="fade-up" data-aos-duration="1000">
                            <CardBody>
                            
                                
                                <div className="custom-form bg-white">
                                    <div id="message"></div>
                                    {this.state.successMsg &&
                                    <Alert color="info" isOpen={this.state.successMsg} toggle={()=>{ this.setState({successMsg : !this.state.successMsg}) }}>
                                        Wiadomośc wysłana{window["push_event"]("formSubmitted")}
                                    </Alert>}
                                    {this.state.error &&
                                    <Alert color="danger" isOpen={this.state.error} toggle={()=>{ this.setState({error : !this.state.error}) }}>
                                        {this.state.error}
                                    </Alert>}
                                {/* FORM action */}

                                    {/* <Form action ="#"> */}
                                    <Form method="post" onSubmit={this.handleSubmit} name="contact-form" id="contact-form">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup className="position-relative">
                                                    <Label>Imię <span className="text-danger">*</span></Label>
                                                    <i><FeatherIcon icon="user" className="fea icon-sm icons" /></i>
                                                    <Input name="name" id="name" type="text" className="form-control pl-5" placeholder="Imię :"
                                                    value={this.state.name}
                                                    onChange={e => this.setState({name: e.target.value}) }
                                                    required/>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup className="position-relative">
                                                    <Label>E-mail <span className="text-danger">*</span></Label>
                                                    <i><FeatherIcon icon="mail" className="fea icon-sm icons" /></i>
                                                    <Input name="email" id="email" type="email" className="form-control pl-5" placeholder="Twój e-mail :"
                                                    value={this.state.email}
                                                    onChange={e => this.setState({email: e.target.value})}
                                                    />
                                                </FormGroup> 
                                            </Col>
                                            <Col lg="12">
                                                <FormGroup className="position-relative">
                                                    <Label>Wiadomość <span className="text-danger">*</span></Label>
                                                    <i><FeatherIcon icon="message-circle" className="fea icon-sm icons" /></i>
                                                    <textarea name="message" id="message"  rows="4" className="form-control pl-5" placeholder="Twoja wiadomość. P. S. : podaj nazwę firmy - będzie nam łatwiej się odnieść do Twojego pytania :"
                                                    value={this.state.message}
                                                    onChange={e => this.setState({message: e.target.value})}
                                                    ></textarea>
                                                </FormGroup>
                                            </Col>
                                            <Col md="12">
                                                <FormGroup>
                                                    <div className="custom-control custom-checkbox">
                                                        <Input type="checkbox" checked={this.state.privacy} className="custom-control-input" name="privacy" id="privacy"
                                                        onChange={this.handleCheckboxChange} />
                                                        <Label className="custom-control-label" htmlFor="privacy">
                                                       Administratorem Pani/Pana danych osobowych jest Up&More Sp. z o.o. z siedzibą w Warszawie (02-628) ul. Krasickiego 11B, Warszawa. Pani/Pana dane osobowe będą przetwarzane w celu obsługi Pani/Pana zapytania. Więcej informacji na temat przetwarzania danych osobowych oraz przysługujących Pani/Panu praw z tym związanych znajduje się w naszej   <a href={PrivacyPolicy}   rel="noopener noreferrer"  className="rounded mr-1">Polityce Prywatności</a>.</Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        
                                        <Row>
                                            <Col sm="12" className="text-center">
                                                <Input className="submitBnt btn btn-primary btn-block" type="submit" onClick={e => this.handleFormSubmit(e)}  value="Wyślij wiadomość"/>
                                                <div id="simple-msg"></div> 
                                                
                                            </Col>
                                        </Row>
                                    </Form>
  
                                    {/* </Form> */}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="3" md={{size:6, order:2}} xs={{order:2}} className="mt-4 pt-2 order-2 order-md-2">
                        <div className="title-heading ml-lg-4">
                            <h4 className="mb-4" data-aos="fade-up" data-aos-duration="1000">Dane kontaktowe</h4>
                            {/* <p className="text-muted" data-aos="fade-up" data-aos-duration="1400">Start working with <span className="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p> */}
                            <Card className="border-0 bg-transparent">
                                <CardBody className="p-0">
                                    <div className="contact-detail d-flex align-items-center mt-3" data-aos="fade-up" data-aos-duration="1200">
                                        <div className="icon">
                                            <i><FeatherIcon icon="mail" className="fea icon-m-md text-dark mr-3" /></i>
                                        </div>
                                        <div className="content overflow-hidden d-block">
                                            <h6 className="font-weight-bold mb-0">E-mail</h6>
                                            <Link to="#" onClick={this.sendMail} className="text-primary" id="click_email">hello@upmore.pl</Link>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>

                            <Card className="border-0 bg-transparent">
                                <CardBody className="p-0">
                                    <div className="contact-detail d-flex align-items-center mt-3" data-aos="fade-up" data-aos-duration="1200">
                                        <div className="icon">
                                            <i><FeatherIcon icon="phone" className="fea icon-m-md text-dark mr-3" /></i>
                                        </div>
                                        <div className="content overflow-hidden d-block">
                                            <h6 className="font-weight-bold mb-0">Telefon</h6>
                                            <Link to="#" onClick={this.callNumber} className="text-primary" id="click_phone"> +48 537 690 900</Link>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            
                            <Card className="border-0 bg-transparent">
                                <CardBody className="list-unstyled social-icon social ">
                                <div className="contact-detail d-flex align-items-center mt-3" data-aos="fade-up" data-aos-duration="1200">
                                <ul className="list-unstyled social-icon social  ">
                                    <li className="list-inline-item mb-0"><a href="https://www.facebook.com/upmorepl" target="_blank"   rel="noopener noreferrer" className="rounded mr-1"><i><FeatherIcon icon="facebook" className="fea icon-sm fea-social" /></i></a></li>
                                    <li className="list-inline-item mb-0"><a href="https://www.instagram.com/agencja_upmore/" target="_blank"   rel="noopener noreferrer"  className="rounded mr-1"><i><FeatherIcon icon="instagram" className="fea icon-sm fea-social" /></i></a></li>
                                    <li className="list-inline-item mb-0"><a href="https://pl.linkedin.com/company/up&more" target="_blank"   rel="noopener noreferrer"  className="rounded mr-1"><i><FeatherIcon icon="linkedin" className="fea icon-sm fea-social" /></i></a></li>
                                </ul>
                                </div>
                                </CardBody>
                            </Card>
                            {/* <Card className="border-0 bg-transparent">
                                <CardBody className="p-0">
                                    <div className="contact-detail d-flex align-items-center mt-3" data-aos="fade-up" data-aos-duration="1200">
                                        <div className="icon">
                                            <i><FeatherIcon icon="map-pin" className="fea icon-m-md text-dark mr-3" /></i>
                                        </div>
                                        <div className="content overflow-hidden d-block">
                                            <h6 className="font-weight-bold mb-0">Lokalizacja</h6>
                                            <Link to="#" className="text-primary">Sprawdź na mapie</Link>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            
                            <Card className="border-0 bg-transparent">
                                <CardBody className ="p-0">
                                <ul className="contact-detail d-flex align-items-center mt-3" data-aos="fade-up" data-aos-duration="1200">
                                    <li className="list-inline-item mb-0"><Link to="#" className="fea icon-m-md text-dark mr-3"><i><FeatherIcon icon="facebook" className="fea icon-sm fea-social" /></i></Link></li>
                                    <li className="list-inline-item mb-0"><Link to="#" className="fea icon-m-md text-dark mr-3"><i><FeatherIcon icon="instagram" className="fea icon-sm fea-social" /></i></Link></li>
                                    <li className="list-inline-item mb-0"><Link to="#" className="rounded mr-1"><i><FeatherIcon icon="linkedin" className="fea icon-sm fea-social" /></i></Link></li>
                                    
                                </ul>
                                </CardBody>
                           
                            </Card> */}
                        </div>
                    </Col>
                </Row>
            </Container>
            </React.Fragment>
        );
    }
}

export default ContactUs;