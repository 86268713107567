import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Badge, Modal,Alert, Card, CardBody, ModalHeader, ModalBody, Form, FormGroup, Input, Button, Label } from "reactstrap";


import axios from "axios";
import FeatherIcon from 'feather-icons-react';

import mobileNotification from "../../assets/images/illustrator/Mobile_notification_SVG.svg";
import PrivacyPolicy from "../../assets/docs/polityka_prywatnosci.pdf"


class Pricing extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            modal : false,
            name : "",
            email: "",
            message: "",
            privacy: false,
            successMsg : false,
            error: null
        }
       
        this.handleSubmit = this.handleFormSubmit.bind(this);
        
    }

    handleCheckboxChange = e => {
        this.setState({privacy: !this.state.privacy})
    }

    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    };

    handleFormSubmit = e => {
        e.preventDefault();
        axios({
          method: 'post',
          url: `https://pozycjonowanie.upmore.pl/kontakt/index.php`,
          headers: { 'content-type': 'application/json' },
          data: this.state
        })
          .then(result => {
            this.setState({
                successMsg : result.data.sent,
                error : result.data.message,
            });
          })
          .catch(errorExt => this.setState({ error: errorExt.message }));
      };
    render() {
        return (
            <React.Fragment>
                    {
                        this.props.pricings.map((pricing, key) =>
                            <Col name="pricing" lg="6" md="6" xs="16" key={key} className="mt-4 pt-2">
                                <Card name="pricingbox" className={ pricing.isActive ? "pricing-rates business-rate shadow bg-white border-0 rounded" : "pricing-rates business-rate shadow bg-light border-0 rounded"}>
                                    <CardBody>
                                    <h2 className={pricing.isActive ? "title text-uppercase text-primary mb-4" : "title text-uppercase mb-4"}>{pricing.title}</h2>
                                    <div className="d-flex mb-4" name="pricingsection">
                                        <span className="h4 mb-0 mt-2"></span>
                                        <span className="price h1 mb-0">{pricing.price}</span>
                                        <span className="h5 align-self-end mb-1">{pricing.duration}</span>
                                    </div>
                                    
                                    <ul className="feature list-unstyled pl-0">
                                        {
                                            pricing.features.map((feature, key) =>
                                                <li key={key} className="feature-list text-muted"><i><FeatherIcon icon="check" className="fea icon-sm text-success mr-2" /></i>{feature.title}</li>
                                            )
                                        }
                                    </ul>
                                    <Link to="#" onClick={this.togglemodal} className="btn btn-success mt-4" id="click_popup3">{pricing.buttonText}</Link>
                                    </CardBody>
                                </Card>
                            </Col>                        
                        )
                    }

                   <Modal isOpen={this.state.modal} role="dialog" centered={true} id="trialform">
                   {this.state.successMsg &&
                                    <Alert color="info" isOpen={this.state.successMsg} toggle={()=>{ this.setState({successMsg : !this.state.successMsg}) }}>
                                        Wiadomośc wysłana{window["push_event"]("formSubmitted")}
                                    </Alert>}
                                    {this.state.error &&
                                    <Alert color="danger" isOpen={this.state.error} toggle={()=>{ this.setState({error : !this.state.error}) }}>
                                        {this.state.error}
                                    </Alert>}
                        <ModalHeader  toggle={this.togglemodal}>
                            Opowiedz nam o potrzebach Twojego biznesu! My znajdziemy rozwiązanie!
                        </ModalHeader>
                        <ModalBody>
                            <div className="feature-form">
                                <img src={mobileNotification} alt=""/>
    
                                <div className="custom-form bg-white">
                                <div id="message"></div>
                              
                                    <Form method = "post" onSubmit={this.handleSubmit} name="contact-form" id="contact-form">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup className="position-relative">
                                                    <Label>Imię <span className="text-danger">*</span></Label>
                                                    <i><FeatherIcon icon="user" className="fea icon-sm icons" /></i>
                                                    <Input name="name" id="name" type="text" className="form-control pl-5" placeholder="Imię :"
                                                     value={this.state.name}
                                                    onChange={e => this.setState({name: e.target.value}) }
                                                    required/>   
                                                </FormGroup>
                                            </Col>
    
                                            <Col lg="6">
                                                <FormGroup className="position-relative">
                                                    <Label>E-mail <span className="text-danger">*</span> </Label>
                                                    <i><FeatherIcon icon="mail" className="fea icon-sm icons" /></i>
                                                    <Input name="email" id="email" type="email" className="form-control pl-5" placeholder="Twój e-mail :"
                                                     value={this.state.email}
                                                     onChange={e => this.setState({email: e.target.value})}
                                                    />
                                                </FormGroup>
                                            </Col>
    
                                            <Col lg="12">
                                                <FormGroup className="position-relative">
                                                    <Label>Wiadomość <span className="text-danger">*</span></Label>
                                                    <i><FeatherIcon icon="message-circle" className="fea icon-sm icons" /></i>
                                                    <textarea name="comments" id="comments" rows="4" className="form-control pl-5" placeholder="Twoja wiadomość. P. S. : podaj nazwę firmy - będzie nam łatwiej się odnieść do Twojego pytania :"
                                                    value={this.state.message}
                                                    onChange={e => this.setState({message: e.target.value})}
                                                    ></textarea>
                                                   
                                                </FormGroup>
                                            </Col>
                                            <Col md="12">
                                                <FormGroup>
                                                    <div className="custom-control custom-checkbox">
                                                        <Input type="checkbox" checked={this.state.privacy} className="custom-control-input" name="privacy1" id="privacy1"
                                                        onChange={this.handleCheckboxChange} />
                                                        <Label className="custom-control-label" htmlFor="privacy1">
                                                       Administratorem Pani/Pana danych osobowych jest Up&More Sp. z o.o. z siedzibą w Warszawie (02-628) ul. Krasickiego 11B, Warszawa. Pani/Pana dane osobowe będą przetwarzane w celu obsługi Pani/Pana zapytania. Więcej informacji na temat przetwarzania danych osobowych oraz przysługujących Pani/Panu praw z tym związanych znajduje się w naszej   <a href={PrivacyPolicy}   rel="noopener noreferrer"  className="rounded mr-1">Polityce Prywatności</a>.</Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
    
                                            {/* <Col lg="12" className="mt-2 mb-0">
                                                <Button color="primary" className="w-100">Wyślij wiadomość</Button>
                                            </Col> */}
                                             <Col sm="12" className="text-center">
                                                <Input type="submit" id="submit" name="send" className="submitBnt btn btn-primary btn-block" type="submit" onClick={e => this.handleFormSubmit(e)} value="Wyślij wiadomość"/>
                                                <div id="simple-msg"></div>
                                            </Col>
                                        </Row>
                                    </Form>                                    
                                </div>
                            </div>
                        </ModalBody>
            </Modal>
            </React.Fragment>

        );
    }
}

export default Pricing;