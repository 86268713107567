import React, { Component } from 'react';
import { Container, Row } from "reactstrap";

//Import Components
import SectionTitle from "../../components/Shared/SectionTitle";
import TeamBox from "../../components/Shared/TeamBox";

//Import Images
import image1 from "../../assets/images/client/Piotr2.jpg";
import image2 from "../../assets/images/client/Tomasz1.jpg";
import image3 from "../../assets/images/client/Ada1.jpg";
import image4 from "../../assets/images/client/Ania1.jpg";

class Team extends Component {
    state = {
        candidates : [
            { id : 1, image : image3, name : "Adriana Napiórkowska", designation : "Senior SEO Specialist", salary : "2300", qualification1: "", qualification: "Posiada 10-letnie doświadczenie zawodowe, karierę rozpoczynała w agencji Emarketing Experts jako młodszy specjalista SEO. Oprócz pozycjonowania stron, zajmowała się prowadzeniem projektów marketingu 360° jako Account Manager w They.pl. Pracowała dla firm z sektora finansowego (Bank Millennium, Bank Meritum, Finai S.A.), e-commerce (np. Black Red White, Autoland, Mumla). W Up&More odpowiada między innymi za projekty SEO dla Panek Car Sharing, Amerigas, Interparking, H+H czy Autoplaza.",
                socialIds : [
                    { can : "bio", link : "#" },
                    // { icon : "instagram", link : "#" },
                    // { icon : "twitter", link : "#" },
                    // { icon : "linkedin", link : "#" },
                ]
            },
            // { id : 2, image : image2, name : "Tomasz Starzyński", designation : "Head of SEO & SEM", salary : "1950", qualification: "Odpowiada za rozwój agencji oraz koordynuje pracę działów SEM/SEO i paid social. Nadzoruje wprowadzanie nowych produktów i narzędzi reklamowych w firmie oraz automatyzację kampanii. Z marketingiem efektywnościowym związany jest od 2005 roku. Jest ekspertem w dziedzinie SEO/SEM, ecommerce, web analityki, marketingu i reklamy internetowej.",link : "",
            //     socialIds : [
            //         { icon : "facebook", link : "#" },
            //         { icon : "instagram", link : "#" },
            //         { icon : "twitter", link : "#" },
            //         { icon : "linkedin", link : "#" },
            //     ]
            // },
            { id : 3, image : image1, name : "Piotr Starzyński", designation : "Head of SEO & Analytics", salary : "2540", qualification: "Ekspert w dziedzinie SEO i analityki internetowej oraz certyfikowany specjalista Google Analytics. Posiada 17 lat doświadczenia. Odpowiada za nadzorowanie działu SEO oraz za proces implementacji analityki na stronach internetowych i w aplikacjach. Opracowuje modele śledzenia aktywności użytkowników, realizuje audyty analityczne, raporty i rekomendacje m.in. optymalizujące działania marketingowe i doświadczenie użytkowników, a także aktywnie wspiera procesy programistyczne." ,link : "",
                socialIds : [
                    { icon : "facebook", link : "#" },
                    { icon : "instagram", link : "#" },
                    { icon : "twitter", link : "#" },
                    { icon : "linkedin", link : "#" },
                ]
            },
            { id : 4, image : image4, name : "Anna Pustovhar", designation : "Junior SEO Specialist", salary : "2190", qualification: "Jest po studiach kierunku stosunki międzynarodowe. Ma  podstawy jako programistka i wielkie zamiłowanie do analityki. Swoją przygodę z SEO rozpoczęła w 2020 roku i prowadzi projekty pod okiem doświadczonych specjalistów. Stale uzupełnia wiedzę uczestnicząc w różnego rodzaju kursach i szkoleniach.", link : "",
                socialIds : [
                    { icon : "facebook", link : "#" },
                    { icon : "instagram", link : "#" },
                    { icon : "twitter", link : "#" },
                    { icon : "linkedin", link : "#" },
                ]
            },
        ]
    }

    render() {
        return (
            <React.Fragment>
                <section className="section bg-light">
                    <Container>
                        {/* section title */}
                        <SectionTitle title="Nasi specjalisci" />
                        {/* <SectionTitle title="Nasi specjalisci" desc="that can provide everything you need to generate awareness, drive traffic, connect." /> */}

                        <Row>
                            {/* teambox */}
                            <TeamBox candidates={this.state.candidates} />
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Team;